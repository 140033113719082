import useAxios from '../../utils/useAxios';
const api = useAxios();

export const getProdutoFinal = async (page, search = '') => {
    const { data } = await api.get(`/produtos_final?page=${page}&search=${search}`);
    return data;
};

export const getProdutoFinalAll = async () => {
    const { data } = await api.get(`/produtos_final_all`);
    return data;
};

export const getProdutoFinalById = async (id) => {
    const { data } = await api.get(`/produtos_final/${id}`);
    return data;
};

export const createProdutoFinal = async (data) =>
    await api.post('/produtos_final', data);

export const editProdutoFinal = async (id, data) =>
    await api.put(`/produtos_final/${id}`, data);

export const deleteProdutoFinal = async (id) =>
    await api.delete(`/produtos_final/${id}`);