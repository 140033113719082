import React, {useEffect} from 'react'
import {useState} from 'react'

import {
  ApartmentOutlined,
  BuildOutlined,
  ExceptionOutlined,
  ProductOutlined,
  ProfileOutlined,
  SolutionOutlined
} from '@ant-design/icons';
import {Menu} from 'antd';
import {Layout} from 'antd';

import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
import { AuthProvider } from './context/AuthContext'

import Home from './views/layout/Home'
import Product from './views/layout/Produtos'
import Contact from './views/layout/Contato'

import Loginpage from './views/Login/Login'

import Perfil from './views/Usuarios/Perfil'

import Tipo from './views/Tipos/Tipos'
import Usuarios from './views/Usuarios/Usuarios'
import Orcamento from './views/Orcamento/Orcamento'

import Produto from './views/Produto/Produto'
import ProdutoFinal from './views/Produto/ProdutoFinal'

import Estoque from './views/Estoque/Estoque'

import HeaderT from './views/layout/Header'
import FooterT from './views/layout/Footer'
import OrdemServico from "./views/OrdemServico/OrdemServico";
import jwt_decode from "jwt-decode";

import swal from "sweetalert2";
import {getMe} from "./router/usuarios/usuarios";

const token = localStorage.getItem("authTokens")
const { Header, Footer, Sider, Content } = Layout;
const permission = token ? jwt_decode(token)?.permissions : null

const isAdminOrFinanceiro = permission?.sigla === 'ADM' || permission?.sigla === 'CV';

const menu = [
  {
    label: 'Tipos de Produto',
    key: 'tipos',
    icon: <ProfileOutlined style={{ fontSize: '170%'}} />,
    style: { display: isAdminOrFinanceiro ? 'block' : 'none' },
  },
  {
    label: 'Produtos',
    key: 'produtos',
    icon: <ProductOutlined style={{ fontSize: '170%'}} />,
    style: { display: isAdminOrFinanceiro ? 'block' : 'none' },
  },
  {
    label: 'Produto Final',
    key: 'produto_final',
    icon: <BuildOutlined style={{ fontSize: '170%'}} />,
    style: { display: isAdminOrFinanceiro ? 'block' : 'none' },
  },
  {
    label: 'Orçamento',
    key: 'orcamento',
    icon: <SolutionOutlined style={{ fontSize: '170%'}} />,
    style: { display: isAdminOrFinanceiro ? 'block' : 'none' },
  },
  {
    label: 'Ordem de Serviço',
    key: 'os',
    icon: <ExceptionOutlined style={{ fontSize: '170%'}} />,
    style: { display: isAdminOrFinanceiro ? 'block' : 'none' },
  },
  {
    label: 'Estoque',
    key: 'estoque',
    icon: <ApartmentOutlined style={{ fontSize: '170%'}} />,
    style: { display: isAdminOrFinanceiro ? 'block' : 'none' },
  }
];

function App() {
  const [collapsed, setCollapsed] = useState(true);
  const { innerWidth: width} = window;
  
  const token = localStorage.getItem("authTokens")
  
  useEffect(() => {
    (async () => {
      if (token) {
        await getMe().catch((e) => {
          if (e.response.status === 401) {
            localStorage.removeItem("authTokens")
            
            location.replace('/login')
            
            swal.fire({
              title: "Você foi desconectado...",
              icon: "success",
              toast: true,
              timer: 3000,
              position: 'top-right',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          }
        })
      }
    })()
  }, [token])
  
  return (
    <Router>
      <AuthProvider>
        <Layout>
          <Header style={{ padding: 0 }}>
            <HeaderT />
          </Header>
          <Layout>
            {width > 700 && token &&
              <Sider style={{
                maxWidth: '150px !important',
                minWidth: '150px !important',
              }} theme="light" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <Menu onClick={({ key }) => {location.replace(key)}} mode="inline" items={menu.filter(item => item.style.display !== 'none')} />
              </Sider>
            }
            
            {width < 700 && token &&
              <Menu mode="horizontal" onClick={({ key }) => {location.replace(key)}} items={menu.filter(item => item.style.display !== 'none')} />
            }
            
            <Layout style={{
              minHeight: "125vh",
            }}>
              <Content
                style={{
                  margin: 0,
                  minHeight: 280,
                  padding: '24px',
                }}
              >
                <Switch>
                  <Route component={Home} path="/" exact />
                  <Route component={Product} path="/produto" exact />
                  <Route component={Contact} path="/contato" exact />
                  
                  <Route component={Loginpage} path="/login" />

                  {isAdminOrFinanceiro && (
                    <>
                      <Route component={Usuarios} path="/usuarios" exact />
                      <Route component={Perfil} path="/perfil" exact />
                      <Route component={Tipo} path="/tipos" exact />
                      <Route component={Orcamento} path="/orcamento" exact />
                      <Route component={OrdemServico} path="/os" exact />
                      <Route component={Estoque} path="/estoque" exact />
                      <Route component={Produto} path="/produtos" exact />
                      <Route component={ProdutoFinal} path="/produto_final" exact />
                    </>
                  )}
                </Switch>
              </Content>
              
              <Footer style={{
                backgroundColor: '#ffffff'
              }}>
                <FooterT />
              </Footer>
            </Layout>
          </Layout>
        </Layout>
      </AuthProvider>
    </Router>
  )
}

export default App