import useAxios from '../../utils/useAxios';
const api = useAxios();

export const getEstoque = async (page) => {
    const { data } = await api.get(`/estoque?page=${page}`);
    return data;
};

export const getEstoqueAll = async () => {
    const { data } = await api.get(`/estoque_all`);
    return data;
};

export const getEstoqueById = async (id) => {
    const { data } = await api.get(`/estoque/${id}`);
    return data;
};

export const createEstoque = async (data) =>
    await api.post('/estoque', data);

export const editEstoque = async (id, data) =>
    await api.put(`/estoque/${id}`, data);

export const deleteEstoque = async (id) =>
    await api.delete(`/estoque/${id}`);