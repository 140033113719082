import axiosT from "../service/api";
import {createContext, useState, useEffect} from "react";
import jwt_decode from "jwt-decode";
import {useHistory} from "react-router-dom";
const swal= require('sweetalert2')

const AuthContext = createContext(undefined);

export default AuthContext

export const AuthProvider = ({ children }) => {
    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem("authTokens")
            ? localStorage.getItem("authTokens")
            : null
    );

    const [user, setUser] = useState(() =>
        localStorage.getItem("authTokens")
            ? localStorage.getItem("authTokens")
            : null
    );


    const [loading, setLoading] = useState(true);

    const history = useHistory();

    const loginUser = async (user, password) => {
        axiosT.post('/login', {
                email: user,
                password: password,
            }, {
                withCredentials: false
            }
        ).then(data => {
            setAuthTokens(data.data.access_token)

            localStorage.setItem("authTokens", data.data.access_token)

            history.push("/")
            location.reload()

            swal.fire({
                title: "Login bem-sucedido!",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        })
        .catch(error => {
            swal.fire({
                title: "Nome de usuário ou senha não existe!",
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        })
    }

    const logoutUser = () => {
        axiosT.post('/logout', {}, {
            headers: {
                'Authorization': `Basic ${localStorage.getItem("authTokens")}`
            }
          }
        ).then((data) => {
            localStorage.removeItem("authTokens")
            
            history.push("/")
            
            swal.fire({
                title: "Você foi desconectado...",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }).catch(error => {
            localStorage.removeItem("authTokens")
            history.push("/")
            swal.fire({
                title: "Nome de usuário ou senha não existe!",
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        })
        setAuthTokens(null)
        setUser(null)
    }
    
    const meUser = () => {
        axiosT.post('/me', {}, {
              headers: {
                  'Authorization': `Basic ${localStorage.getItem("authTokens")}`
              }
          }
        ).then((data) => {
            location.replace('/')
        }).catch(error => {
            localStorage.removeItem("authTokens")
            location.replace('/')
        })
    }

    const contextData = {
        user, 
        setUser,
        authTokens,
        setAuthTokens,
        loginUser,
        logoutUser,
        meUser
    }

    useEffect(() => {
        if (authTokens) {
            setUser(jwt_decode(authTokens))
        }
        setLoading(false)
    }, [authTokens, loading])

    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    )

}
