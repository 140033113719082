import {useState, useEffect} from 'react'
import {
  Col,
  Button,
  Form,
  Typography,
  Row,
  Divider,
  Space,
  Table,
  Layout,
  InputNumber,
  Select,
  Spin,
  Tag
} from 'antd';

import swal from "sweetalert2";
import {
  getEstoque,
  deleteEstoque,
  createEstoque,
  editEstoque,
  getEstoqueById,
  getEstoqueAll
} from "../../router/estoque/estoque";
import {CloseOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {getProdutoAll} from "../../router/produto/produtos";

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

const { Title } = Typography;
const { Content } = Layout;
const { Column } = Table;

function Estoque() {
  const [form] = Form.useForm();
  
  const [reload, setReload] = useState()
  const [status, setStatus] = useState(true)
  const [edit, setEdit] = useState([false, null])

  const [saida, setSaida] = useState()
  
  const [attorney, setAttorney] = useState([])
  const [attorneyProdutos, setAttorneyProdutos] = useState([])
  const [attorneys, setAttorneys] = useState([])
  
  const [page, setPage] = useState(1)
  const [pageAll, setPageAll] = useState(0)
  const [produtosAll, setProdutosAll] = useState([])
  
  const [loading, setLoading] = useState(true)
  
  const handleSubmit = async e => {
    e.items.map(item => {
      item.nf = e.nf
      item.saida = saida
      item.valor = item.valor ? item.valor : 0
      
      return item
    })

    if (edit[0]) {
      await editEstoque(edit[1], e)
      setReload(new Date())
      setEdit([false, null])
      setStatus(true)
      
      swal.fire({
        title: "Estoque editado",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    } else {
      await createEstoque(e)
      setReload(new Date())
      setEdit([false, null])
      setStatus(true)
      
      swal.fire({
        title: "Estoque criado",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    }
  }
  
  useEffect(() => {
    (async () => {
      const attorneys = []
      
      const data =  await getEstoque(page).catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("authTokens")
          
          location.replace('/')
          
          swal.fire({
            title: "Você foi desconectado...",
            icon: "success",
            toast: true,
            timer: 3000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
          })
        }
      })
      
      const produtos_all = await getProdutoAll()
      
      const newProdutosAll = produtos_all.data.map((item) => {
        return {
          value: item.id,
          label: item.nome + ' - ' + item.tipos.nome,
        }
      })
      
      setProdutosAll(newProdutosAll)
      setPageAll(data.meta.total)
      
      for (const item of data.data) {
        const label = newProdutosAll.find((element) => element.value === item.tipo_id)
        item.key = item.id
        item.tipo = label ? label.label : null;
        attorneys.push(item)
      }
      
      setAttorneys(attorneys)
      setLoading(false)
    })()
  }, [reload, page])
  
  const gerarPdf = async () => {
    const attorney = await getEstoqueAll();
    
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    
    const reportTitle = [
      {
        text: 'Estoque',
        fontSize: 15,
        bold: true,
        margin: [15, 20, 0, 45] // left, top, right, bottom
      }
    ];
    
    const dadosEstoque = attorney.map((item) => {
      return [
        {
          text: item.nome,
          fontSize: 10,
          colSpan: 2,
          color: 'gray',
        },
        {},
        {
          text: parseInt(item.saldo),
          fontSize: 10,
          color: 'gray',
          alignment: 'center'
        },
        {
          text: item.tipo,
          fontSize: 10,
          color: 'gray',
          alignment: 'center'
        }
      ]
    });
    
    const details = {
      content: [
        {
          style: 'tableExample',
          table: {
            widths: [100, '*', 200, '*'],
            body: [
              [
                {
                  text: 'Produto',
                  style: 'tableHeader',
                  colSpan: 2,
                },
                {},
                {
                  text: 'Quantidade',
                  style: 'tableHeader',
                  alignment: 'center'
                },
                {
                  text: 'Tipo',
                  style: 'tableHeader',
                  alignment: 'center'
                },
              ],
              ...dadosEstoque
            ]
          }
        },
      ]
    };
    
    function Rodape(currentPage, pageCount){
      return [
        {
          text: currentPage + ' / ' + pageCount,
          alignment: 'right',
          fontSize: 9,
          margin: [0, 10, 20, 0] // left, top, right, bottom
        }
      ]
    }
    
    const docDefinitios = {
      pageSize: 'A4',
      pageMargins: [15, 50, 15, 40],
      
      header: [reportTitle],
      content: [details.content],
      footer: Rodape
    }
    
    const pdf = pdfMake.createPdf(docDefinitios);
    pdf.getBlob((blod) => {
      const url = URL.createObjectURL(blod)
      window.open(url, '_blank')
    })
  }
  
  const onRemove = ( id ) => {
    deleteEstoque(id)
      .then(async () => {
        setReload(new Date())
        swal.fire({
          title: "Você removeu o produto!",
          icon: "success",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        })
      })
      .catch(() => {
        swal.fire({
          title: "Algo deu errado ao remover o produto!",
          icon: "error",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        })
      })
  }
  
  const onEdit = async ( id ) => {
    form.resetFields();
    const attorney = await getEstoqueById(id);

    setAttorneyProdutos([{
      produtos_id: attorney.data.produtos_id,
      quantidade: attorney.data.quantidade,
      valor: attorney.data.valor ? attorney.data.valor : 0,
    }])
    
    setAttorney(attorney.data)
    
    setSaida(attorney.data.saida)
    setReload(new Date())
    setStatus(false)
    setEdit([true, id])
  }
  
  const onCreate = async (value) => {
    form.setFieldsValue({
      nf: '',
      items: '',
      valor: '',
      quantidade: '',
      produtos_id: ''
    });
    
    setSaida(value)
    setReload(new Date())
    setStatus(false)
    setEdit([false, null])
  }
  
  return (
    <Content>
      <Title level={3}>Estoque</Title>
      
      <Divider />
      
      {loading && <>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
                position: 'absolute',
                left: '50%',
                top: '50%',
              }}
              spin
            />
          }
        />
      </>}
      
      {status && <>
        <Row gutter={16}>
          <Col><Button style={{backgroundColor: '#52c41a'}} type="primary" htmlType="reset" onClick={() => onCreate(false)} loading={loading}>Entrada</Button></Col>
          <Col><Button style={{backgroundColor: '#f5222d'}} type="primary" htmlType="reset" onClick={() => onCreate(true)} loading={loading}>Saída</Button></Col>
          <Col><Button type="primary" htmlType="reset" onClick={() => gerarPdf()} loading={loading}>Vísualizar</Button></Col>
        </Row>
        
        <Divider />
        {!loading && <>
          <Table dataSource={attorneys} pagination={{
            onChange: setPage,
            pageSize: 15,
            total: pageAll,
          }}
           scroll={{
             x: true,
           }}>
            <Column title="Número" dataIndex="nf" key="nf" render={(_, record) =>
              record.nf ? parseInt(record.nf) : record.nf
            } />
            <Column title="Produto" dataIndex="nome" key="nome" render={(_, record) =>
              record.produtos.nome
            } />
            <Column title="Valor" dataIndex="valor" key="valor" render={(_, record) =>
              parseFloat(record.valor).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
            } />
            <Column title="Data" dataIndex="created_at" key="created_at" render={(_, record) =>
              record.created_at.split('T')[0].split('-').reverse().join('/')
              + ' ' +
              record.created_at.split('T')[1].split('.')[0]
            } />
            <Column title="Quantidade" dataIndex="quantidade" key="quantidade" render={(_, record) =>
              parseFloat(record.quantidade)
            } />
            <Column title="Tipo" dataIndex="tipo" key="tipo" render={(_, record) =>
              record.produtos.tipos.sigla
            } />
            <Column title="Status" dataIndex="saida" key="saida" render={(_, record) =>
              record.saida
                ? <Tag color='volcano' key='true'> Saída </Tag>
                : <Tag color='green' key='false'> Entrada </Tag>
            } />
            <Column
              title="Ações"
              key="action"
              render={(_, record) => (
                <Space size="middle">
                  <Button type="primary" onClick={() => onEdit(record.id)}>Editar</Button>
                  <Button type="primary" onClick={() => onRemove(record.id)} danger>Remover</Button>
                </Space>
              )}
            />
          </Table>
        </>}
      </>}
      
      {!status && <>
        <Title level={4}>{saida ? "Saida" : "Entrada"}</Title>
        
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={6} md={4}>
              <Form.Item
                name="nf"
                label="Número Nota Físcal"
                initialValue={attorney.nf}
              >
                <InputNumber style={{width: '100%'}}/>
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={24} md={24}>
              <Form.List name="items" initialValue={attorneyProdutos}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name}) => (
                      <div style={{padding: '0 3rem'}} key={key}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={12} sm={12} md={12}>
                            <Form.Item
                              name={[name, 'produtos_id']}
                              label="Produto"
                              rules={[
                                {
                                  required: true,
                                  message: 'Produto requerido',
                                },
                              ]}
                            >
                              <Select
                                style={{width: '100%'}}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={produtosAll}
                              />
                            </Form.Item>
                          </Col>
                          
                          <Col xs={5} sm={5} md={5}>
                            <Form.Item
                              name={[name, 'quantidade']}
                              label="Quantidade"
                              rules={[
                                {
                                  required: true,
                                  message: 'Quantidade requerida',
                                },
                              ]}
                            >
                              <InputNumber style={{width: '100%'}}/>
                            </Form.Item>
                          </Col>
                          
                          <Col xs={5} sm={5} md={5}>
                            <Form.Item
                              name={[name, 'valor']}
                              label="Valor"
                            >
                              <InputNumber
                                style={{width: '100%'}}
                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                              />
                            </Form.Item>
                          </Col>
                          <CloseOutlined onClick={() => remove(name)} />
                        </Row>
                      </div>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Adcionar Produto
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
            
            {/*<Col xs={24} sm={24} md={10}>
              <Form.Item
                name="produtos_id"
                label="Produto"
                rules={[
                  {
                    required: true,
                    message: 'Produto requerido',
                  },
                ]}
                initialValue={attorney.produtos_id}
              >
                <Select
                  style={{width: '100%'}}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                    options={produtosAll}
                />
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={6} md={4}>
              <Form.Item
                name="quantidade"
                label="Quantidade"
                rules={[
                  {
                    required: true,
                    message: 'Quantidade requerida',
                  },
                ]}
                initialValue={attorney.quantidade}
              >
                <InputNumber style={{width: '100%'}}/>
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={6} md={4}>
              <Form.Item
                name="valor"
                label="Valor"
                initialValue={attorney.valor ?? 0}
              >
                <InputNumber
                  style={{width: '100%'}}
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>*/}
            
            <Col span={24}>
              <Space size="middle">
                <Button type="primary" htmlType="submit">Salvar</Button>
                <Button type="primary" danger onClick={() => setStatus(true)}>Cancelar</Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </>}
    </Content>
  )
}

export default Estoque