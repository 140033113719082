import {useState, useEffect} from 'react'
import {
  Col,
  Button,
  Form,
  Input,
  Typography,
  Row,
  Divider,
  Space,
  Layout,
  Spin,
  Select
} from 'antd';

import {editUsuarios, getMe} from "../../router/usuarios/usuarios";
import {LoadingOutlined} from "@ant-design/icons";
import swal from "sweetalert2";

const { Title } = Typography;
const { Content } = Layout;

function Perfil() {
  const [form] = Form.useForm();

  const [password, setPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordStrengthRepeat, setPasswordStrengthRepeat] = useState('');
  
  const [attorneys, setAttorneys] = useState([])
  
  const [loading, setLoading] = useState(true)

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setPasswordStrength(checkPasswordStrength(newPassword));
  };

  const handlePasswordChangeRepeat = (event) => {
    const newPasswordRepeat = event.target.value;
    if (newPasswordRepeat === password) {
      setPasswordStrengthRepeat('Igual');
    } else {
      setPasswordStrengthRepeat('Senha não corresponde');
    }
  };

  const checkPasswordStrength = (password) => {
    if (password.length < 8) {
      return <span style={{ color: "red" }}>Fraca</span>;
    } else if (password.match(/[a-zA-Z]/) && password.match(/[0-9]/)) {
      return <span style={{ color: "green" }}>Forte</span>;
    } else {
      return <span style={{ color: "yellow" }}>Média</span>;
    }
  };

  const handleSubmit = async (value) => {
    await editUsuarios(attorneys.id, value)
    
    location.replace('/')
  }
  
  useEffect(() => {
    (async () => {
      const data =  await getMe().catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("authTokens")
          
          location.replace('/login')
          
          swal.fire({
            title: "Você foi desconectado...",
            icon: "success",
            toast: true,
            timer: 3000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
          })
        }
      })
      
      setAttorneys(data)
      setLoading(false)
    })()
  }, [])

  return (
    <Content>
      <Title level={3}>Perfil</Title>
      
      <Divider />
      
      {loading && <>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
                position: 'absolute',
                left: '50%',
                top: '50%',
              }}
              spin
            />
          }
        />
      </>}
      
      {!loading && <>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={7}>
              <Form.Item
                name="name"
                label="Nome"
                rules={[
                  {
                    required: true,
                    message: 'Nome requerido',
                  },
                ]}
                initialValue={attorneys.name}
              >
                <Input />
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={24} md={7}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Email requerido',
                  },
                ]}
                initialValue={attorneys.email}
              >
                <Input />
              </Form.Item>
            </Col>
            
            <Col span={24}>
              <Divider />
              
              <Title level={4}>Acesso</Title>
              
              <Divider />
            </Col>
            
            <Col xs={24} sm={24} md={7}>
              <Form.Item
                name="password"
                label="Senha"
              >
                <Input.Password onChange={(e) => handlePasswordChange(e)}/>
              </Form.Item>
              <>{passwordStrength}</>
            </Col>
            
            <Col xs={24} sm={24} md={7}>
              <Form.Item
                name="password_confirm"
                label="Repetir Senha"
              >
                <Input.Password onChange={(e) => handlePasswordChangeRepeat(e)} />
              </Form.Item>
              <>{passwordStrengthRepeat}</>
            </Col>
            
            <Col span={24}>
              <Space size="middle">
                <Button type="primary" htmlType="submit">Salvar</Button>
                {/*<Button type="primary" danger onClick={() => setStatus(true)}>Cancelar</Button>*/}
              </Space>
            </Col>
          </Row>
        </Form>
      </>}
    </Content>
  )
}

export default Perfil