import useAxios from '../../utils/useAxios';
const api = useAxios();

export const getOrdemServico = async (page, search = '') => {
    const { data } = await api.get(`/ordem_servico/${true}?page=${page}&search=${search}`);
    return data;
};

export const getOrcamentoById = async (id) => {
    const { data } = await api.get(`/orcamento/${id}`);
    return data;
};

export const getOrdemServicoByIdPdf = async (id) => {
    const { data } = await api.get(`/ordem_servico_pdf/${id}`);
    return data;
};

export const getOrcamentoByIdAprove = async (id) => {
    const { data } = await api.get(`/orcamento_aprove/${id}`);
    return data;
};

export const createOrcamento = async (data) =>
    await api.post('/orcamento', data);

export const editOrcamento = async (id, data) =>
    await api.put(`/orcamento/${id}`, data);

export const deleteOrcamento = async (id) =>
    await api.delete(`/orcamento/${id}`);