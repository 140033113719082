import {useState, useEffect} from 'react'
import {
  Col,
  Button,
  Form,
  Input,
  Typography,
  Row,
  Divider,
  Space,
  Table,
  Layout,
  Spin,
  Select
} from 'antd';

import swal from "sweetalert2";
import {LoadingOutlined} from "@ant-design/icons";
import {
  createUsuarios,
  deleteUsuarios,
  editUsuarios,
  getUsuarios,
  getUsuariosById
} from "../../router/usuarios/usuarios";

const { Title } = Typography;
const { Content } = Layout;
const { Column } = Table;

function Tipo() {
  const [form] = Form.useForm();

  const [reload, setReload] = useState()
  const [status, setStatus] = useState(true)
  const [edit, setEdit] = useState([false, null])

  const [attorney, setAttorney] = useState([])
  const [attorneys, setAttorneys] = useState([])

  const [page, setPage] = useState(1)
  const [pageAll, setPageAll] = useState(0)
  
  const [loading, setLoading] = useState(true)
  
  const [search, setSearch] = useState()
  
  const [password, setPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordStrengthRepeat, setPasswordStrengthRepeat] = useState('');
  
  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setPasswordStrength(checkPasswordStrength(newPassword));
  };
  
  const handlePasswordChangeRepeat = (event) => {
    const newPasswordRepeat = event.target.value;
    if (newPasswordRepeat === password) {
      setPasswordStrengthRepeat('Igual');
    } else {
      setPasswordStrengthRepeat('Senha não corresponde');
    }
  };
  
  const checkPasswordStrength = (password) => {
    if (password.length < 8) {
      return <span style={{ color: "red" }}>Fraca</span>;
    } else if (password.match(/[a-zA-Z]/) && password.match(/[0-9]/)) {
      return <span style={{ color: "green" }}>Forte</span>;
    } else {
      return <span style={{ color: "yellow" }}>Média</span>;
    }
  };
  
  const handleSubmit = async e => {
    if (password.password === password.password_confirm)
      if (edit[0]) {
        await editUsuarios(edit[1], e)
  
        setEdit([false, null])
        setStatus(true)
        setReload(new Date())
  
        swal.fire({
          title: "Usuario editado",
          icon: "success",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        })
      } else {
        await createUsuarios(e)
  
        setEdit([false, null])
        setStatus(true)
        setReload(new Date())
  
        swal.fire({
          title: "Usuario criado",
          icon: "success",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        })
      }
  }

  useEffect(() => {
    (async () => {
      const attorneys = []
      const data =  await getUsuarios(page, search).catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("authTokens")
          
          location.replace('/')
          
          swal.fire({
            title: "Você foi desconectado...",
            icon: "success",
            toast: true,
            timer: 3000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
          })
        }
      })
      
      setPageAll(data.meta.total)

      for (const item of data.data) {
        item.key = item.id
        attorneys.push(item)
      }
      
      setAttorneys(attorneys)
      setLoading(false)
    })()
  }, [reload, page, search])

  const onRemove = ( id ) => {
    deleteUsuarios(id)
      .then(async () => {
        setReload(new Date())
        swal.fire({
          title: "Você removeu o advogado!",
          icon: "success",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        })
      })
      .catch(() => {
        swal.fire({
          title: "Algo deu errado ao remover o advogado!",
          icon: "error",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        })
      })
  }

  const onEdit = async ( id ) => {
    form.resetFields();
    const attorney = await getUsuariosById(id);
    setAttorney(attorney.data)
    
    setStatus(false)
    setEdit([true, id])
  }

  const onCreate = async ( ) => {
    form.setFieldsValue({
      name: '',
      email: '',
      permissions_id: '',
      password: '',
      password_confirm: '',
    });
    setStatus(false)
    setEdit([false, null])
  }
  
  return (
    <Content>
      <Title level={3}>Usuarios</Title>
      
      <Divider />
      
      {loading && <>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
                position: 'absolute',
                left: '50%',
                top: '50%',
              }}
              spin
            />
          }
        />
      </>}
      
      {status && <>
        <Row>
          <Col span={8}>
            <Button type="primary" onClick={() => onCreate()} loading={loading}>Cadastrar</Button>
          </Col>
          <Col span={8} offset={8}>
            <Input placeholder="Pesquisa" onChange={(e) => e.target.value.length >= 3 ? setSearch(e.target.value) : setSearch('')} />
          </Col>
        </Row>
        
        <Divider />
        
        {!loading && <>
          <Table dataSource={attorneys} pagination={{
            onChange: setPage,
            pageSize: 15,
            total: pageAll,
          }}
           scroll={{
             x: true,
           }}>
            <Column title="Nome" dataIndex="name" key="name" />
            <Column title="Email" dataIndex="email" key="email" />
            <Column
              title="Ações"
              key="action"
              render={(_, record) => (
                  <Space size="middle">
                    <Button type="primary" onClick={() => onEdit(record.id)}>Editar</Button>
                    <Button type="primary" onClick={() => onRemove(record.id)} danger>Remover</Button>
                  </Space>
              )}
            />
          </Table>
        </>}
      </>}

      {!status && <>
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={7}>
              <Form.Item
                name="name"
                label="Nome"
                rules={[
                  {
                    required: true,
                    message: 'Nome requerido',
                  },
                ]}
                initialValue={attorney.name}
              >
                <Input />
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={24} md={7}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Email requerido',
                  },
                ]}
                initialValue={attorney.email}
              >
                <Input />
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={24} md={7}>
              <Form.Item
                name="permissions_id"
                label="Permissão"
                rules={[
                  {
                    required: true,
                    message: 'Sigla requerido',
                  },
                ]}
                initialValue={attorney.permissions_id}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={[
                    {
                      value: 1,
                      label: 'Administrador',
                    },
                    {
                      value: 2,
                      label: 'Financeiro',
                    },
                    {
                      value: 3,
                      label: 'Recursos Humanos',
                    },
                    {
                      value: 4,
                      label: 'Compra e Venda',
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            
            <Col span={24}>
              <Divider />
              
              <Title level={4}>Acesso</Title>
              
              <Divider />
            </Col>
            
            <Col xs={24} sm={24} md={7}>
              <Form.Item
                name="password"
                label="Senha"
                rules={[
                  {
                    required: true,
                    message: 'Senha Obrigatoria!',
                  },
                ]}
              >
                <Input.Password onChange={(e) => handlePasswordChange(e)}/>
              </Form.Item>
              <>{passwordStrength}</>
            </Col>
            
            <Col xs={24} sm={24} md={7}>
              <Form.Item
                name="password_confirm"
                label="Repetir Senha"
                rules={[
                  {
                    required: true,
                    message: 'Repetir Senha!',
                  },
                ]}
              >
                <Input.Password onChange={(e) => handlePasswordChangeRepeat(e)} />
              </Form.Item>
              <>{passwordStrengthRepeat}</>
            </Col>
            
            <Col span={24}>
              <Space size="middle">
                <Button type="primary" htmlType="submit">Salvar</Button>
                <Button type="primary" danger onClick={() => setStatus(true)}>Cancelar</Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </>}
    </Content>
  )
}

export default Tipo