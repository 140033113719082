import {useState, useEffect} from 'react'
import {
  Button,
  Typography,
  Divider,
  Space,
  Table,
  Layout,
  Spin, Col, Input, Row,
} from 'antd';

import swal from "sweetalert2";
import {LoadingOutlined} from "@ant-design/icons";
import {getOrdemServico, getOrdemServicoByIdPdf} from "../../router/ordem_servico/ordem_servico";

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

const { Title } = Typography;
const { Content } = Layout;
const { Column } = Table;

function Tipo() {
  const [attorneys, setAttorneys] = useState([])

  const [page, setPage] = useState(1)
  const [pageAll, setPageAll] = useState(0)
  
  const [loading, setLoading] = useState(true)
  
  const [search, setSearch] = useState()
  
  useEffect(() => {
    (async () => {
      const attorneys = []
      const data =  await getOrdemServico(page, search).catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("authTokens")
          
          location.replace('/')
          
          swal.fire({
            title: "Você foi desconectado...",
            icon: "success",
            toast: true,
            timer: 3000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
          })
        }
      })
      
      setPageAll(data.meta.total)

      for (const item of data.data) {
        item.key = item.id
        attorneys.push(item)
      }

      setAttorneys(attorneys)
      setLoading(false)
    })()
  }, [page, search])

  const gerarPdf = async (id) => {
    const attorney = await getOrdemServicoByIdPdf(id);
    
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    
    const reportTitle = [
      {
        text: 'Materiais do Orçamento',
        fontSize: 15,
        bold: true,
        margin: [15, 20, 0, 45] // left, top, right, bottom
      }
    ];
    
    const dadosProdutos = attorney.orcamento_produtos.map((item) => {
      return [
        {
          text: item.quantidade_produtos[0].nome_produto,
          fontSize: 10,
          colSpan: 2,
          color: 'gray',
        },
        {},
        {
          text: parseFloat(item.quantidade_produtos[0].quantidade) + ' - ' + item.quantidade_produtos[0].tipo,
          fontSize: 10,
          color: 'gray',
          alignment: 'center'
        },
        {
          text: `${(parseFloat(item.quantidade_produtos[0].quantidade) * item.quantidade_produtos[0].valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`,
          fontSize: 10,
          color: 'gray',
          alignment: 'right'
        }
      ]
    });
    
    const produto = attorney.orcamento_produtos.length > 0 ? [
      {
        text: "Tabela de produtos",
        style: 'subheader',
        fontSize: 12,
        margin: [10, 5, 0, 5]
      },
      {
        style: 'tableExample',
        table: {
          widths: [100, '*', 200, '*'],
          body: [
            [
              {
                text: 'Produto',
                style: 'tableHeader',
                colSpan: 2,
              },
              {},
              {
                text: 'Quantidade',
                style: 'tableHeader',
                alignment: 'center'
              },
              {
                text: 'Valor Uni.',
                style: 'tableHeader',
                alignment: 'right'
              },
            ],
            ...dadosProdutos
          ]
        }
      },
    ] : []
    
    const dadosProdutosFinal = attorney.orcamento_produtos_final.map((itemFinal) => {
      const dadosProdutosFinalProdutos = itemFinal.quantidade_produtos.map((item) => {
        return [
          {
            text: item.nome_produto,
            fontSize: 10,
            colSpan: 2,
            color: 'gray'
          },
          {},
          {
            text: parseFloat(item.quantidade) + ' - ' + item.tipo,
            fontSize: 10,
            color: 'gray',
            alignment: 'center'
          },
          {
            text: `${(parseFloat(item.quantidade) * item.valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`,
            fontSize: 10,
            color: 'gray',
            alignment: 'right'
          }
        ]
      });
      
      return [
        {
          style: 'tableExample',
          table: {
            widths: [100, '*', 200, '*'],
            headerRows: 2,
            body: [
              [
                {
                  text: itemFinal.produtos_final.nome,
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center'
                },
                {},
                {},
                {
                  text: parseInt(itemFinal.quantidade),
                  style: 'tableHeader',
                  alignment: 'center'
                },
              ],
              [
                {text: 'Produto', colSpan: 2, style: 'tableHeader'},
                {},
                {text: 'Quantidade',style: 'tableHeader', alignment: 'center'},
                {text: 'Valor Uni.', style: 'tableHeader', alignment: 'right'}
              ],
              ...dadosProdutosFinalProdutos
            ]
          }
        }
      ]
    });
    
    const produtoFinal = attorney.orcamento_produtos_final.length > 0 ? [{
      text: "Tabela de produtos finais",
      style: 'subheader',
      fontSize: 12,
      margin: [10, 5, 0, 5]
    },
      ...dadosProdutosFinal,
    ] : []
    
    const details = {
      content: [
        {
          text: attorney.descricao,
          style: 'subheader',
          fontSize: 12,
          bold: true,
          margin: [10, 5, 0, 5]
        },
        ...produto,
        ...produtoFinal,
      ]
    };
    
    function Rodape(currentPage, pageCount){
      return [
        {
          text: currentPage + ' / ' + pageCount,
          alignment: 'right',
          fontSize: 9,
          margin: [0, 10, 20, 0] // left, top, right, bottom
        }
      ]
    }
    
    const docDefinitios = {
      pageSize: 'A4',
      pageMargins: [15, 50, 15, 40],
      
      header: [reportTitle],
      content: [details.content],
      footer: Rodape
    }

    const pdf = pdfMake.createPdf(docDefinitios);
    pdf.getBlob((blod) => {
      const url = URL.createObjectURL(blod)
      window.open(url, '_blank')
    })
  }

  return (
    <Content>
      <Title level={3}>Ordem de Serviço</Title>
      
      <Divider />
      
      {loading && <>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
                position: 'absolute',
                left: '50%',
                top: '50%',
              }}
              spin
            />
          }
        />
      </>}
      
      {!loading && <>
        <Row>
          <Col span={8}>
          </Col>
          <Col span={8} offset={8}>
            <Input placeholder="Pesquisa" onChange={(e) => e.target.value.length >= 3 ? setSearch(e.target.value) : setSearch('')} />
          </Col>
        </Row>
        
        <Divider />

        <Table dataSource={attorneys} pagination={{
          onChange: setPage,
          pageSize: 15,
          total: pageAll,
        }}
         scroll={{
           x: true,
         }}>
          <Column title="Descrição" dataIndex="descricao" key="descricao" />
          <Column
            title="Ações"
            key="action"
            render={(_, record) => (
                <Space size="middle">
                  <Button style={{backgroundColor: 'rgb(189, 186, 15)'}} type="primary" onClick={() => gerarPdf(record.id)}>Imprimir</Button>
                </Space>
            )}
          />
        </Table>
      </>}
    </Content>
  )
}

export default Tipo